.selected {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);
  cursor: default;
}

.non-selected {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
}

.commentList {
  width: 240px;
  height: calc(100% - 72px);

  .MuiBackdrop-root {
    display: none;
  }

  .MuiDrawer-paper {
    height: inherit;
    z-index: 1000;
  }

  .MuiDrawer-paperAnchorRight {
    right: 48px;
    background-color: rgba(33, 33, 33, .8);
    border-left: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0 0 0 8px;
  }
}